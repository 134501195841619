<template>
	<v-sheet class="recurring-main-listing position-relative" style="height: calc(100vh - 125px)">
		<v-layout class="flex-wrap">
			<div class="py-3 d-flex align-center justify-content-between w-100">
				<h4 class="fw-500 title-text mr-4" style="white-space: nowrap">Other Notification list</h4>
				<div class="d-flex">
					<v-text-field
						outlined
						hide-details
						placeholder="Enter Contact Person..."
						v-model="contact_person"
						class="w-10 mr-2"
					></v-text-field>
					<v-text-field
						outlined
						hide-details
						placeholder="Enter Notification..."
						v-model="notification"
						class="w-10 mr-2"
					></v-text-field>
					<v-text-field
						outlined
						hide-details
						placeholder="Enter Status..."
						v-model="status"
						class="w-10 mr-2"
					></v-text-field>
					<v-select
						:items="notificationOnList"
						outlined
						hide-details
						class="w-10"
						v-model="notification_on"
					></v-select>
					<v-btn color="blue darken-4 white--text" tile min-height="30" class="ml-3">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn>
				</div>
			</div>
		</v-layout>
		<div class="mb-3 overflow-auto">
			<v-btn-toggle v-model="toggle_exclusive" mandatory color="primary" dense tile>
				<v-btn class="blue--text btn-border"> ALL </v-btn>
				<v-btn
					class="btn-border"
					color="blue darken-4 white--text"
					v-for="(data, index) in filter"
					:key="index"
				>
					{{ data }}
				</v-btn>
			</v-btn-toggle>
		</div>
		<div class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height">
				<table class="w-100">
					<thead>
						<tr>
							<th width="40">
								<v-checkbox dense v-model="checkbox" hide-details class="m-0 p-0"></v-checkbox>
							</th>
							<th>Sr. No</th>
							<th>Contact Person</th>
							<th>Notification</th>
							<th>Status</th>
							<th>Notification On</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="i in 5" :key="i">
							<td>
								<v-checkbox dense v-model="checkbox" hide-details class="m-0 p-0"></v-checkbox>
							</td>
							<td>1</td>
							<td>John</td>
							<td>Lorem</td>
							<td><v-chip color="green white--text" label>Success</v-chip></td>
							<td>Lorem</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<v-pagination class="listing-pagination" :length="3" v-model="page"> </v-pagination>
	</v-sheet>
</template>
<script>
export default {
	name: "NotificationListing",
	data() {
		return {
			page: 1,
			checkbox: false,
			notification_on: "Select Notification On...",
			notificationOnList: ["Select Notification On..."],
			contact_person: null,
			notification: null,
			status: null,
			filter: [
				"A",
				"B",
				"C",
				"D",
				"E",
				"F",
				"G",
				"H",
				"I",
				"J",
				"K",
				"L",
				"M",
				"N",
				"O",
				"P",
				"Q",
				"R",
				"S",
				"T",
				"U",
				"V",
				"W",
				"X",
				"Y",
				"Z",
			],
		};
	},
	//   methods: {
	//     route_to_detail() {
	//       this.dialog = false;
	//       this.$router.push({name: "service-inspection", query: {
	//         'is-vitas': 0,
	//         'is-net-total': 1,
	//         'is-e-wallet': 0,
	//         't-group': 'Product',
	//         'c-code': 'CASH',
	//         'is-service-detail': 1
	//       }});
	//     }
	//   },
	//   mounted() {
	//     this.$root.$on('open-product-dialog',() => {
	//       this.dialog = true;
	//     })
	//   }
};
</script>
